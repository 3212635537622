import "./post.scss";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Link } from "react-router-dom";
import Comments from "../comments/Comments";
import { useState, useContext } from "react";
import moment from "moment";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import { notification } from 'antd';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const Post = ({ post }) => {
  const [commentOpen, setCommentOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [saved,setSaved] = useState(false);

  const handleLike = () => {
    makeRequest
      .post(
        `/users/postreact/addReactOnPost/${currentUser.id}/${post.postId}`,
        {
          postReactName: "Like",
          postReactImageUrl: "string",
        }
      )
      .then((res) => {
        console.log(res);
        queryClient.invalidateQueries(['posts']);
      });
  };

  const handleRemoveLike = ()=>{
    makeRequest
     .delete(
        `/users/postreact/deleteReactOnPost/${currentUser.id}/${post.postId}`,
        {
          postReactName: "Like",
        }
      )
     .then((res) => {
        queryClient.invalidateQueries(['posts']);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const mutation = useMutation((postId) => {
    console.log(postId)
      const response = makeRequest.delete(`/users/posts/deletPost/${postId}`);
      console.log(response)
      if(response){
        console.log("post deleted")
        return response.data
      }
      else{
        console.log("error deleting post")
      }
  }, 
  {
    onSuccess: () => {
      queryClient.invalidateQueries(['posts']);
      notification.success({
        message: 'Post has been deleted',
      });
    },
  });


  const handleDelete = (postId) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      mutation.mutate(postId);
      console.log("delete post", postId);
    }
  };

  const handleSavePost=async(postId)=>{
    try {
      const response = await makeRequest.post(`/users/savepost/savePost/${currentUser.id}/${postId}`)
      if(response.status === 201){
        console.log("post saved", response)
        setSaved(true)
        notification.success({
          message: response.data.message,
        });
      }
      else{
        notification.error({
          message: response.data.message,
        });
      }
    } catch (error) { 
      console.log("error saving post", error)
      notification.error({
        message: error.response.data.message,
      });
    }
  }

  const renderMedia = () => {
    if (post?.postType?.includes("video")) {
      return (
        <div className="video-post">
        <video controls>
          <source src={post.postImageURl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </div>
      );
    } else {
      return <img src={post.postImageURl} alt="" />;
    }
  };

  return (
    <div className="post">
      <div className="container">
        <div className="user">
          <div className="userInfo">
            <img src={post.userProfileImageUrl} alt="" />
            <div className="details">
              <Link
                to={`/profile/${post.userId ? post.userId : "1"}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <span className="name">{post.userName}</span>
              </Link>
              <span className="date">
                {moment(post.postCreatedAt).fromNow()}
              </span>
            </div>
          </div>
          <MoreHorizIcon onClick={() => setMenuOpen(!menuOpen)} />
            {
              menuOpen &&  <div  className="moreOptions" style={{zIndex:'100'}}>
                {
                  post.userId !== currentUser.id &&
                  <>
                  <button className="more-button" onClick={() => handleSavePost(post.postId)}>{saved ? "saved" : 'save'} <BookmarkIcon className="size-4"/></button>
                  <button className="more-button">Report <ErrorIcon className="size-4"/></button>
                  </>
                }
              {
              post.userId === currentUser.id && (
                  <div>
                    <button className="more-button" onClick={() => handleDelete(post.postId)} style={{backgroundColor:"red",color:"white"}}>Delete <DeleteIcon className="size-4"/></button>
                  </div>
                )
              }
            </div>
            }
        
          {/* {menuOpen && post.userId === currentUser.id && (
            <div>
              <button onClick={()=>handleDelete(post.postId)}>Delete</button>
            </div>
          )} */}
        </div>
        <div className="content">
          <p>{post.postName || ""}</p>
          {renderMedia()}
        </div>
        <div className="info">
          <div className="item">
            {
              post.userReactStatus ? <FavoriteOutlinedIcon onClick={handleRemoveLike}/> : <FavoriteBorderOutlinedIcon onClick={handleLike} />
            }
            
            {post.totalCountOFReact}
          </div>
          <div className="item" onClick={() => setCommentOpen(!commentOpen)}>
            <TextsmsOutlinedIcon />
            {commentOpen ? "Hide Comments" : "See Comments"}
          </div>
          {/* <div className="item">
            <ShareOutlinedIcon />
            Share
          </div> */}
        </div>
        {commentOpen && (
          <Comments
            postId={post.postId}
            setCommentOpen={setCommentOpen}
            commentOpen={commentOpen}
          />
        )}
      </div>
    </div>
  );
};

export default Post;
