import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import image from "../../assets/register_image.jpg";
import user from "../../assets/user2.png";
import "./singlePage.scss";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ProfileTabsPages from "./ProfileTabsPages";
import { makeRequest } from "../../axios";
import AWS from "aws-sdk";
import { notification } from "antd";
import heic2any from "heic2any";
import SendIcon from "@mui/icons-material/Send";
import { AuthContext } from "../../context/authContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Image from "../../assets/img.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Modal } from "antd";
import { nativeSelectClasses } from "@mui/material";

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

function UserPage() {
  const params = useParams();
  // console.log(params)
  const [selectedTab, setSelectedTab] = useState("Posts");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {currentUser} = useContext(AuthContext)

  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");
  const S3_BUCKET = "streesakti";
  const [inputNameError, setInputNameError] = useState("");
  const [seletedModalComponent, setSeletedModalComponent] = useState(null);
  const [groupAbout,setGroupAbout] = useState({})
  const [groupMembers,setGroupMembers] = useState([])
  const [pendingFriends,setPendingFriends] = useState([])
  const [posts, setPosts] = useState([]);

  const navigate = useNavigate();

  const getGroupDetails = async () => {
    try {
      const response = await makeRequest.get(`/users/pages/getPageDetails/${params.id}`);
      // console.log(response)
      setGroupAbout(response.data.groupAbout || {});
      setGroupMembers(response.data.groupMembers || []);
      setPendingFriends(response.data.pendingFriends || []);
      setPosts(response.data.postDetails || []);
    } catch (error) {
      console.error("Error fetching group details:", error);
      notification.error({
        message: "Fetch Failed",
        description: "There was an error fetching the group details.",
      });
    }
  };

  
  useEffect(() => {
    getGroupDetails()
  }, [params.id])

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion,
  });

  const s3 = new AWS.S3();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newPost) => {
      return makeRequest.post(
        `/users/group/addPost/${currentUser.id}/${groupAbout.groupId}`,
        newPost
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["posts"]);
        queryClient.invalidateQueries(["lastestActivities"]);
        notification.success({
          message: "Post uploaded successfully",
          description: "Your post has been shared with everyone.",
        });
      },
    }
  );

  const handleInput = (e) => {
    setDesc(e.target.value);
    if (e.target.value.length > 0) {
      setInputNameError("");
    }
  };

  const handleClick = async () => {
    if (desc.trim() === "") {
      setInputNameError("Description is required");
      return;
    }

    let fileToUpload = file;
    if (file && file.type === "image/heic") {
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
        });
        fileToUpload = new File(
          [convertedBlob],
          file.name.replace(/\.[^/.]+$/, ".jpg"),
          { type: "image/jpeg" }
        );
      } catch (error) {
        console.error("Error converting HEIC to JPEG:", error);
        notification.error({
          message: "Conversion Failed",
          description: `There was an error converting your HEIC image: ${error.message}`,
        });
        return;
      }
    }

    let postImageUrl = null;
    if (file) {
      const params = {
        Bucket: S3_BUCKET,
        Key: `posts/${fileToUpload.name}`,
        Body: fileToUpload,
      };

      try {
        const data = await s3.upload(params).promise();
        postImageUrl = data.Location;
      } catch (error) {
        console.error("Error uploading file:", error);
        notification.error({
          message: "Upload Failed",
          description: `There was an error uploading your file: ${error.message}`,
        });
        return;
      }
    }

    mutation.mutate({
      postName: desc,
      postImageUrl: postImageUrl,
      postType: file ? fileToUpload.type : null,
    });

    setFile(null);
    setDesc("");
  };

  const handleBack = () => {
    navigate(-1);
  };


  const handleModal = (component) => {
    setIsModalVisible(true);
    setSeletedModalComponent(component)
  }

  let content;
  switch (seletedModalComponent){
    case "addMembers":
      content = <AddMembers pendingFriends={pendingFriends} grp={groupAbout}/>
      break;
    case "updateGroup":
      content = <UpdateGroup groupAbout={groupAbout}/>
      break;
    case "groupSettings":
      content = <GroupSettings/>
      break;
  }

  return (
    <div className="grpSingle" style={{ height: "90vh", overflow: "scroll" }}>
      <div className="goBack">
        <button onClick={handleBack} className="goBack">
          <ArrowBackIosIcon className="size-4" />
        </button>
      </div>
      <div>
        <img src={groupAbout.pageCoverProfileImagePath} alt="group" />
      </div>
      <div className="detailsGrp">
        <div className="head">
          <img src={groupAbout.adminUserProfileImagePath} width={"100px"} height={"100px"} />
        </div>
        <div className="invite">
          <h1>{groupAbout.pageName}</h1>
          {
            groupAbout.adminId !== currentUser.id &&
              <button className="button">Follow</button>
          }
        </div>
        <div className="Tabs">
          <div className="profileTab">
            <div className="tabs">
              <button
                className={selectedTab === "Posts" ? "active" : ""}
                onClick={() => setSelectedTab("Posts")}
              >
                Posts
              </button>
              <button
                className={selectedTab === "AboutGroup" ? "active" : ""}
                onClick={() => setSelectedTab("AboutGroup")}
              >
                About
              </button>
              <button
                className={selectedTab === "Friends" ? "active" : ""}
                onClick={() => setSelectedTab("Friends")}
              >
                Members
              </button>
            </div>
          </div>
          <div className="more">
            <button>
              <MoreVertIcon className="size-3" />
            </button>
          </div>
        </div>

        <div className="grpBody">
          <div className="leftBody">
            {
              groupAbout.adminId === currentUser.id &&
              <div className="shareGrp">
              <div className="container">
                <div className="top">
                  <div className="left">
                    <img src={currentUser.profilePic} alt="" />
                    <textarea
                      rows={"3"}
                      type="text"
                      placeholder={`What do you want to share on this page, ${currentUser.name}?`}
                      onChange={handleInput}
                      value={desc}
                    />
                  </div>
                  <div className="right">
                    {file && file.type.startsWith("image/") && (
                      <img
                        className="file"
                        alt=""
                        src={URL.createObjectURL(file)}
                      />
                    )}
                    {file && file.type.startsWith("video/") && (
                      <video className="file" controls>
                        <source
                          src={URL.createObjectURL(file)}
                          type={file.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {!file && <p>No file selected</p>}{" "}
                    {/* Message when no file is selected */}
                  </div>
                </div>
                {inputNameError && (
                  <span className="inputError">{inputNameError}</span>
                )}
                <hr />
                <div className="bottom">
                  <div className="left">
                    <input
                      type="file"
                      id="file"
                      accept="image/*,video/*"
                      style={{ display: "none" }}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <label htmlFor="file">
                      <div className="item">
                        <img src={Image} alt="" />
                        <span>Add Image/Video</span>
                      </div>
                    </label>
                  </div>
                  <div className="rightGrp">
                    <button onClick={handleClick}>
                      Share
                      <SendIcon
                        style={{ fontSize: "1.3rem" }}
                        sx={{ color: "#000" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            }
            <ProfileTabsPages selectedTab={selectedTab} post={posts} userId={currentUser.id} data={groupAbout} />
          </div>
          <div className="rightBody">
            {
              groupAbout.adminId !== currentUser.id ? null : (
                <p onClick={()=> handleModal("updateGroup")}>Update Page</p>
              )
            }
          </div>
          <Modal
            visible={isModalVisible}
            // onOk={handleLogout}
            onCancel={() => setIsModalVisible(false)} 
            footer={null}
          >
         {content}
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default UserPage;

export const GroupSettings=()=>{
  return (
    <div>
      <h1>Group settings</h1>
    </div>
  )
}

export const AddMembers=({pendingFriends,grp})=>{
  const {currentUser} = useContext(AuthContext)
  console.log("pendingFriends",pendingFriends,currentUser)
  console.log("grp",grp)

  const [selectedUser, setSelectedUser] = useState([]);
  const navigate = useNavigate()

  const addToGroup = (userId)=>{
    setSelectedUser((prev)=>{
      if(prev.includes(userId)){
        return prev.filter((Id)=>Id!==userId)
      }
      else{
        return [...prev, userId]
      }
    })
  }

  const addPendingToGroup=async()=>{
    if(selectedUser.length ===0){
      alert("Please select atleast one friend")
      return;
    }
    else{
      const userParams = selectedUser && selectedUser.length > 0 && selectedUser.map(id => `userId=${id}`).join("&")
      const response = await makeRequest.post(`/users/group/addmemberToGroup/${grp.groupId}/${currentUser.id}?${userParams}`)
      console.log("response",response)
      if(response){
        notification.success({
          message: 'Members added successfully',
          description: "You can also add other members to this group.",
        });
        setSelectedUser([])
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to add members to the group.",
        });
      }
    }
  }

  return (
    <div>
      <h1 style={{margin:"30px"}}>Add Members</h1>

      <div className="list" style={{width:"80%",height:"50%",margin:"auto",marginBottom:"20px"}}>
      {
        pendingFriends && pendingFriends.length > 0 ?
        pendingFriends.map((item,index)=>{
          return(
            <div key={index} className='friend' style={{display:"flex",justifyContent:"space-between"}}>
                <div style={{display:'flex',gap:'10px',justifyContent:'center',alignItems:'center'}}>
                <img src={item.userProfileImagePath} alt={item.userFirstName} width="30px" height="30px" style={{borderRadius:"50px"}} />
                <p>{item.userFirstName} {item.userLastName}</p>
                </div>
                <input type='checkbox' onClick={()=>addToGroup(item.userId)}/>
              </div>
          )
        })
        :
        <div style={{marginTop:"20px"}}>No pending friends to add</div>
      }
      </div>
      <button style={{backgroundColor:'#d8ae7e',border:"none",padding:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={addPendingToGroup}>Add To Group</button>
    </div>
  )
}

export const UpdateGroup=({groupAbout})=>{
  // console.log("this is a group",groupAbout)

  const [newGroupName, setNewGroupName] = useState(groupAbout.groupName)
  const [newGroupDescription, setNewGroupDescription] = useState(groupAbout.groupDescription)
  const [newFile,setNewFile] = useState()
  const {currentUser} = useContext(AuthContext)

  const S3_BUCKET = "streesakti";

  const navigate= useNavigate()

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion,
  });

  const S3 = new AWS.S3();

  const handleGroupUpdate=async()=>{
    if(!newGroupName ||!newGroupDescription){
      alert("Please fill all the fields")
      return;
    }
    else{
      const params = {
        Bucket: S3_BUCKET,
        Key: `groupCover/${newFile.name}`,
        Body: newFile,
      };
      
      const data = await S3.upload(params).promise();
      
      const response = await makeRequest.put(`/users/group/updateGroup/${currentUser.id}/${groupAbout.groupId}`, {
        groupName: newGroupName,
        groupDescription: newGroupDescription,
        groupCoverProfileImagePath: data.Location,
      });
      console.log(response);
      if(response){
        notification.success({
          message: 'Group updated successfully',
        });
        navigate(`/groups`)
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to update group details.",
        });
      }
    }
  }

  return (
    <div>
      <h1 style={{margin:"20px"}}>Update group</h1>
      <hr/>
      <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Group Name</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} name="newGroupName" value={newGroupName} onChange={(e)=>setNewGroupName(e.target.value)} type="text" />
      </div>
      <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Group Description</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} name="newGroupDescription" value={newGroupDescription} onChange={(e)=>setNewGroupDescription(e.target.value)} type="text" />
      </div>
      <div style={{display:"flex",flexDirection:'column',margin:'20px'}}>
      <label htmlFor="grpName" style={{fontWeight:"bold",fontSize:'1rem'}}>Change Cover photo</label>
      <input id="grpName" style={{width:'80%',padding:'5px'}} placeholder={groupAbout.groupDescription} type="file" onChange={(e)=>setNewFile(e.target.files[0])} />
      {
        newFile && <img src={URL.createObjectURL(newFile)} alt="" style={{width:'100px',height:'100px'}} />
      }
      </div>

      <button style={{backgroundColor:'#d8ae7e',border:"none",padding:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={handleGroupUpdate} >Update</button>
    </div>
  )
}
