import React from 'react'
import "./userPageCard.scss"
import { Link } from 'react-router-dom'
import image from "../../assets/admin_img.jpg"
import moment from 'moment'

function UserPageCard({page}) {
  console.log(page)
  let desc = page.pageDescription.slice(0,35)
  console.log(desc)
  return (
    <div className='card'>
        <Link to={`/userpage/${page.pagesId}`}>
      <img src={page.pageCoverProfileImagePath} alt="user" />
      <h3>{page.pageName}</h3>
      <p>{page.category}</p>
      <h6>{desc + "..."}</h6>
      <h6>Created by {page.adminUserFirstName} {page.adminUserLastName}</h6> 
       <h6>Created on {moment(page.pageCreatedAt).format('MMMM Do YYYY') }</h6>
        </Link>
    </div>
  )
}

export default UserPageCard
