import React from 'react';
import Stories from 'react-insta-stories';
import moment from 'moment';
import { makeRequest } from '../../../axios';

const StoriesModal = ({ setOpenStories, selectedStories , currentUser}) => {
const isMobile = window.innerWidth < 786;
  return (

    <div >
      <Stories
        stories={selectedStories.storyDetails.map((storiesList) => {
          makeRequest.post(`ssakti/users/story/addViewedStory/${currentUser.id}/${storiesList.storyId}`);
          return ({
            url: storiesList.storyUrl,
            type: storiesList.storyType,
            header: {
              heading: selectedStories.userFirstName + " " + selectedStories.userLastName,
              subheading: moment(storiesList.storyCreatedAt).fromNow(),
              profileImage: selectedStories.userProfileImagePath,
            }

          })

        })}
        // onStoryStart={()=> {
        //   makeRequest.post(`ssakti/users/story/addViewedStory/${currentUser.id}/${storiesList.storyId}`)
        // }}
        defaultInterval={1500}
        width={isMobile ? "100vw" : 423}
        height={isMobile ? "100vh" :768}
      />
    </div>
  );
};

export default StoriesModal;
