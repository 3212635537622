import React, { useState,useContext } from "react";
import Post from "../../components/post/Post";
import AboutGroup from "./AboutGroup";
import FriendsListing from ".././friendsListing/FriendsListng"
import image from "../../assets/register_image.jpg";
import user from "../../assets/user2.png";
import "./singleGroup.scss";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeRequest } from "../../axios";
import AWS from "aws-sdk";
import { notification } from "antd";
import heic2any from "heic2any";
import SendIcon from "@mui/icons-material/Send";
import { AuthContext } from "../../context/authContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Image from "../../assets/img.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Modal } from "antd";
import { nativeSelectClasses } from "@mui/material";

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

const ProfileTabsGroup = ({ userId, selectedTab ,data,post}) => {

  return (
    <div className="profileTabs">
    
      <div className="tabContent">
        {selectedTab === "Posts" && <GroupPost post={post} data={data} />}
        {selectedTab === "AboutGroup" && <AboutGroup data={data}/>}
        {selectedTab === "Friends" && <FriendsListing userId={userId} />}
      </div>
    </div>
  );
};

export default ProfileTabsGroup;


export const GroupPost = ({post,data})=>{


  const S3 = new AWS.S3();
  const queryClient = useQueryClient();
  const {currentUser} = useContext(AuthContext)

  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");
  const S3_BUCKET = "streesakti";
  const [inputNameError, setInputNameError] = useState("");

  const handleInput = (e) => {
    setDesc(e.target.value);
    if (e.target.value.length > 0) {
      setInputNameError("");
    }
  };

  const mutation = useMutation(
    (newPost) => {
      return makeRequest.post(
        `/users/group/addPost/${currentUser.id}/${data.groupId}`,
        newPost
      );
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["grpPost"]);
        // queryClient.invalidateQueries(["lastestActivities"]);
        notification.success({
          message: "Post uploaded successfully",
          description: "Your post has been shared with everyone.",
        });
      },
    }
  );

  // console.log('this is post :',post)
  const handleClick = async () => {
    if (desc.trim() === "") {
      setInputNameError("Description is required");
      return;
    }

    let fileToUpload = file;
    if (file && file.type === "image/heic") {
      try {
        const convertedBlob = await heic2any({
          blob: file,
          toType: "image/jpeg",
        });
        fileToUpload = new File(
          [convertedBlob],
          file.name.replace(/\.[^/.]+$/, ".jpg"),
          { type: "image/jpeg" }
        );
      } catch (error) {
        console.error("Error converting HEIC to JPEG:", error);
        notification.error({
          message: "Conversion Failed",
          description: `There was an error converting your HEIC image: ${error.message}`,
        });
        return;
      }
    }

    let postImageUrl = null;
    if (file) {
      const params = {
        Bucket: S3_BUCKET,
        Key: `posts/${fileToUpload.name}`,
        Body: fileToUpload,
      };

      try {
        const data = await S3.upload(params).promise();
        postImageUrl = data.Location;
      } catch (error) {
        console.error("Error uploading file:", error);
        notification.error({
          message: "Upload Failed",
          description: `There was an error uploading your file: ${error.message}`,
        });
        return;
      }
    }

    mutation.mutate({
      postName: desc,
      postImageUrl: postImageUrl,
      postType: file ? fileToUpload.type : null,
    });

    setFile(null);
    setDesc("");
  };
  return(
    <>
    <div className="shareGrp">
              <div className="container">
                <div className="top">
                  <div className="left">
                    <img src={currentUser.profilePic} alt="" />
                    <textarea
                      rows={"3"}
                      type="text"
                      placeholder={`What's on your mind ${currentUser.name}?`}
                      onChange={handleInput}
                      value={desc}
                    />
                  </div>
                  <div className="right">
                    {file && file.type.startsWith("image/") && (
                      <img
                        className="file"
                        alt=""
                        src={URL.createObjectURL(file)}
                      />
                    )}
                    {file && file.type.startsWith("video/") && (
                      <video className="file" controls>
                        <source
                          src={URL.createObjectURL(file)}
                          type={file.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {!file && <p>No file selected</p>}{" "}
                    {/* Message when no file is selected */}
                  </div>
                </div>
                {inputNameError && (
                  <span className="inputError">{inputNameError}</span>
                )}
                <hr />
                <div className="bottom">
                  <div className="left">
                    <input
                      type="file"
                      id="file"
                      accept="image/*,video/*"
                      style={{ display: "none" }}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <label htmlFor="file">
                      <div className="item">
                        <img src={Image} alt="" />
                        <span>Add Image/Video</span>
                      </div>
                    </label>
                  </div>
                  <div className="rightGrp">
                    <button onClick={handleClick}>
                      Share
                      <SendIcon
                        style={{ fontSize: "1.3rem" }}
                        sx={{ color: "#fff" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
    {
      post.length == 0 ?
      <p>No posts found.</p>
      :
      post.map((post)=>{
        return(
          <Post post={post} key={post.postId}/>
        )
      })
    }
    </>
  )
}