import './usersAndPostsData.scss'
import { makeRequest } from '../../../axios';
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { Card, Statistic, arr } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const UserAndPostsData = () => {

  const { isLoading, isError, data } = useQuery(["AdminUserAndPosts"], () => {
    return makeRequest.get(`/admin/userActivity`).then((res) => {
      return res.data;
    });
  });;

  return (
    <div className="userAndPostsData">
      <div className="d-info-item">
        <div className="d-info-title">
          Total Users Count
        </div>
        <div className="d-info-content">
          {/* <span>{data?.totalActiveUser || "no data"}</span> */}
        
            
            <Statistic
              // title="last 30 days"
              value={data?.totalActiveUser || "no data"}
              // precision={2}
              valueStyle={{
                color: '#3f8600',
              }}
              prefix={<ArrowUpOutlined />}
              // suffix="%"
            />

        </div>
      </div>
      <div className="d-info-item">
        <div className="d-info-title">
          New Registrations
        </div>
        <div className="d-info-content">
          {/* <span>{data?.lastThirtyDaysActiveUser || "no data"}</span> */}
          <Statistic
              // title="last 30 days"
              value={data?.lastThirtyDaysActiveUser || "no data" }
              // precision={2}
              valueStyle={{
                color: '#3f8600',
              }}
              prefix={<ArrowUpOutlined />}
              // suffix="%"
            />
        </div>
      </div>
      <div className="d-info-item">
        <div className="d-info-title">
          New Posts Added
        </div>
        <div className="d-info-content">
          {/* <span>{data?.lastThirtyDaysPosts || "no data"}</span> */}
          <Statistic
              // title="last 30 days"
              value={data?.lastThirtyDaysPosts || "no data" }
              // precision={2}
              valueStyle={{
                color: '#3f8600',
              }}
              prefix={<ArrowUpOutlined />}
              // suffix="%"
            />
        </div>
      </div>
    </div>
  )
}

export default UserAndPostsData;