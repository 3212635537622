import { useContext, useState } from "react";
import "./comments.scss";
import { AuthContext } from "../../context/authContext";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import moment from "moment";

const Comments = ({ postId, setCommentOpen, commentOpen }) => {
  const [desc, setDesc] = useState("");
  const { currentUser } = useContext(AuthContext);
  const queryClient = useQueryClient();

  // const { data: commentList, isLoading, isError } = useQuery(
  //   ["comments", postId],
  //   () => makeRequest.get(`/ssakti/users/postcomment/getCommentsAndReacts/${postId}`)
  // );

  const { isLoading, isError, data:commentList } = useQuery(["comments"], () => {
    return makeRequest.get(`/users/postcomment/getCommentsAndReacts/${postId}`).then((res) => {
      return res.data.commentsAndReacts;
    });
  });;

  const mutation = useMutation(
    (newComment) => {
      return makeRequest.post(
        `/users/postcomment/addCommentOnPost/${currentUser.id}/${postId}`,
        {
          comment: newComment,
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["lastestActivities"]);
        queryClient.invalidateQueries(["comments"]);
        // queryClient.setQueryData(["comments", postId], (oldComments) => [
        //   {
        //     comment: data.comment,
        //     commentId: data.commentId,
        //     commentTime: data.commentTime,
        //     userId: data.userId,
        //     userName: data.userName,
        //     userProfileImageUrl: data.userProfileImageUrl,
        //   },
        //   ...oldComments,
        // ]);
        setDesc("");
      },
    }
  );

  if (isLoading) return "Loading...";
  if (isError) return "Something went wrong";

  return (
    <div className="comments">
      <div className="write">
        <img src={currentUser.profilePic} alt="" />
        <input
          type="text"
          placeholder="write a comment"
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
        />
        <button onClick={() => mutation.mutate(desc)}>Send</button>
      </div>
      {commentList.length < 1 ? (
        "No comments yet"
      ) : (
        <>
          {commentList.map((comment) => (
            <div className="comment">
              <img src={comment.userProfileImageUrl} alt="" />
              <div className="info">
                <span>{comment.userName}</span>
                <p>{comment.comment}</p>
              </div>
              <span className="date">
                {moment(comment.commentTime).fromNow()}
              </span>
            </div>
          ))}

          {commentOpen ? (
            <div className="item" onClick={() => setCommentOpen(false)}>
              Hide Comments
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Comments;