import React, { useState } from "react";
import Post from "../../components/post/Post";
import AboutPage from "./AboutPage";
import FriendsListing from ".././friendsListing/FriendsListng"
// import "./profileTabs.scss"

const ProfileTabsPages = ({ userId, selectedTab ,data,post}) => {

  return (
    <div className="profileTabs">
    
      <div className="tabContent">
        {selectedTab === "Posts" && <PagesPost post={post} />}
        {selectedTab === "AboutGroup" && <AboutPage data={data}/>}
        {selectedTab === "Friends" && <FriendsListing userId={userId} />}
      </div>
    </div>
  );
};

export default ProfileTabsPages;


export const PagesPost = ({post})=>{
  // console.log('this is post :',post)
  return(
    <>
    {
      post.length == 0 ?
      <p>No posts found.</p>
      :
      post.map((post)=>{
        return(
          <Post post={post} key={post.postId}/>
        )
      })
    }
    </>
  )
}